import { Grid as MuiGrid, List as MuiList, ListItem as MuiListItem, Stack as MuiStack } from '@mui/material'
import { styled } from '@mui/system'
import Button from '../../molecules/button'

const shouldForwardProp = (prop) => !['look', 'message', 'position'].includes(prop)

const Header = styled('header', { shouldForwardProp })``

const GridContainer = styled(MuiGrid)`
	padding: ${({ theme }) => theme.spacing(2)};
`

const LogoWrapper = styled('div')`
	max-width: 150px;
	width: 100%;
`

const StackStyled = styled(MuiStack, { shouldForwardProp })`
	.mini-trigger {
		padding: 0;
	}
`

const ListStyled = styled(MuiList)`
	padding: ${({ theme }) => theme.spacing(9, 2.75, 0)};
`

const ListItemStyled = styled(MuiListItem)`
	padding-bottom: ${({ theme }) => theme.spacing(1.25)};
	padding-top: ${({ theme }) => theme.spacing(1.25)};
`

const ButtonAction = styled(Button)`
	border-bottom: 0;
	.MuiButton-startIcon {
		margin-right: ${({ theme }) => theme.spacing(2.5)};
	}
	.MuiButton-iconSizeMedium.MuiButton-startIcon .MuiSvgIcon-fontSizeSmall {
		color: ${({ theme }) => theme.palette.primary.main};
		font-size: ${({ theme }) => theme.typography.pxToRem(19)};
	}
`

export { ButtonAction, GridContainer, Header, ListItemStyled, ListStyled, LogoWrapper, StackStyled }
