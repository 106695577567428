import { arrayOf, object } from 'prop-types'
import { useState, useEffect } from 'react'
import IconMenu1 from '../../atoms/icons/menu-1-icon'
import IconClose from '../../atoms/icons/x-icon'
import MultiDrawerMenu from '../../molecules/menu/multi-drawer-menu'
import ServiceLinks from './service-links'
import { HamburgerButton, SwipeableDrawer, ButtonClose } from './sidebar-navigation.styled'
import router from 'next/router'
import { useUser } from '@bluheadless/ui-logic/src/providers/user'
import Button from '../../molecules/button/button'
import { PAGE_PATH_LOGIN } from '@bluheadless/ui-logic/src/constants'
import { useCmsBlock } from '@bluheadless/ui-logic/src/hooks/cms/useCmsBlock'
import Block from '../../particles/cms-block/block'
import { FormattedMessage } from 'react-intl'

const IconMenu = () => <IconMenu1 fontSize="middle" color="primary" />

const SidebarNavigation = ({
	className,
	hamburgerButtonProps,
	multiDrawerMenuProps,
	buttonCloseProps,
	serviceLinksProps,
	anchor,
	...props
}) => {
	useEffect(() => {
		const onRouteChangeStart = () => {
			setToggleSidebar(false)
		}
		router.events.on('routeChangeStart', onRouteChangeStart)
		return () => {
			router.events.off('routeChangeStart', onRouteChangeStart)
		}
	}, [])

	const [toggleSidebar, setToggleSidebar] = useState(false)
	const [{ isLoggedIn }] = useUser()
	const { content } = useCmsBlock({ identifier: 'mobile-menu-bottom' })

	return (
		<>
			<HamburgerButton
				variant="icon"
				Icon={IconMenu}
				onClick={() => setToggleSidebar(true)}
				{...hamburgerButtonProps}
			/>
			<SwipeableDrawer
				className={className}
				open={toggleSidebar}
				onOpen={() => {}}
				onClose={() => setToggleSidebar(false)}
				disableSwipeToOpen
				anchor={anchor}
				ModalProps={{
					keepMounted: true,
				}}
				{...props}
			>
				<ButtonClose
					variant="icon"
					Icon={IconClose}
					iconProps={{ fontSize: 'medium' }}
					className="iconClose"
					color="black"
					onClick={() => setToggleSidebar(false)}
					{...buttonCloseProps}
				/>
				<MultiDrawerMenu anchor={anchor} toggleSidebar={toggleSidebar} {...multiDrawerMenuProps} />
				<ServiceLinks {...serviceLinksProps} />
				{!!content && <Block content={content} />}
				{!isLoggedIn && (
					<Button
						variant="contained"
						color="secondary"
						href={PAGE_PATH_LOGIN}
						label={<FormattedMessage id="signup_or_login" />}
					/>
				)}
			</SwipeableDrawer>
		</>
	)
}

SidebarNavigation.defaultProps = {
	anchor: 'right',
	disableBackdropTransition: true,
	keepMounted: true,
	minFlingVelocity: 200,
}

SidebarNavigation.propTypes = {
	hamburgerButtonProps: object,
	menuItems: arrayOf(object),
	multiDrawerMenuProps: object,
	serviceLinksProps: object,
}

export default SidebarNavigation
