/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const AccountIconSvg = forwardRef((props, svgRef) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path fill="none" d="M2.7 2.7h18.6v18.6H2.7z" />
		<path d="M19.5 21.3c-.5 0-.9-.4-.9-.9v-1.9c0-1.6-1.3-2.9-2.9-2.9H8.2c-1.6 0-2.9 1.3-2.9 2.9v1.9c0 .5-.4.9-.9.9s-.9-.4-.9-.9v-1.9C3.6 16 5.7 14 8.2 14h7.5c2.5 0 4.6 2.1 4.6 4.6v1.9c.1.4-.3.8-.8.8zM12 11.9c-2.5 0-4.6-2.1-4.6-4.6 0-2.5 2.1-4.6 4.6-4.6 2.5 0 4.6 2.1 4.6 4.6 0 2.6-2.1 4.6-4.6 4.6zm0-7.5c-1.6 0-2.9 1.3-2.9 2.9s1.3 2.9 2.9 2.9 2.9-1.3 2.9-2.9-1.3-2.9-2.9-2.9z" />
	</svg>
))
AccountIconSvg.displayName = 'AccountIconSvg'

const AccountIcon = forwardRef((props, ref) => <Icon component={AccountIconSvg} ref={ref} {...props} />)
AccountIcon.displayName = 'AccountIcon'

AccountIcon.defaultProps = {
	...Icon.defaultProps,
}
AccountIcon.propTypes = {
	...Icon.propTypes,
}

export default AccountIcon
export { AccountIconSvg }
