import { styled } from '@mui/system'
import MenuList from '@mui/material/MenuList'
import Button from '../../molecules/button'

const Root = styled(MenuList)`
	margin-top: auto;
	margin-bottom: 33px;
	padding: 0;
	padding-top: 50px;
	.MuiMenuItem-root {
		border: 0;
		padding-top: 0px;
		padding-bottom: 12px;
		font-family: ${({ theme }) => theme.typography.fontFamilyAlternative};
		color: ${({ theme }) => theme.palette.grey.light};
		min-height: unset;
		&:last-of-type {
			padding-bottom: 0;
		}
		&:hover {
			background: transparent;
		}
		.MuiLink-root {
			display: flex;
			align-items: center;
			font-family: ${({ theme }) => theme.typography.fontFamilyAlternative};
			color: ${({ theme }) => theme.palette.grey.light};
			&:hover {
				color: ${({ theme }) => theme.palette.grey.main};
			}
			> button,
			> svg {
				margin-right: 20px;
			}
		}
		.change-country-language-trigger-sidebar {
			p {
				font-family: ${({ theme }) => theme.typography.fontFamilyAlternative};
			}
		}
	}
`

const ButtonIcon = styled(Button)`
	padding: 0;
	.MuiSvgIcon-root {
		font-size: ${({ theme }) => theme.typography.pxToRem(18)};
	}
`

export { ButtonIcon, Root }
