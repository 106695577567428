/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const HeartIconSvg = forwardRef((props, svgRef) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path d="M20.4 4.5c-2.3-2.3-6-2.4-8.4-.1-2.4-2.2-6.2-2.1-8.4.2-2.2 2.3-2.2 6 0 8.3l8.4 8.4 8.4-8.4c2.3-2.3 2.3-6 0-8.4zm-1.6 6.9L12 18.2l-6.8-6.8c-1.4-1.5-1.4-3.8 0-5.2C6.6 4.6 9 4.6 10.5 6L12 7.5 13.5 6c1.5-1.5 3.9-1.4 5.4.1 1.4 1.4 1.4 3.8-.1 5.3z" />
	</svg>
))
HeartIconSvg.displayName = 'HeartIconSvg'

const HeartIcon = forwardRef((props, ref) => <Icon component={HeartIconSvg} ref={ref} {...props} />)
HeartIcon.displayName = 'HeartIcon'

HeartIcon.defaultProps = {
	...Icon.defaultProps,
}
HeartIcon.propTypes = {
	...Icon.propTypes,
}

export default HeartIcon
export { HeartIconSvg }
