import { useSearchContext } from '@bluheadless/ui-logic/src/providers/search'
import { Grid as MuiGrid } from '@mui/material'
import { cx } from '@emotion/css'
import { number, oneOf, shape, string } from 'prop-types'
import MultiDrawerMenu from '../../molecules/menu/multi-drawer-menu'
import NotifyMessage from '../../molecules/notify-message'
import SearchTrigger from '../../molecules/search'
import SidebarNavigation from '../sidebar-navigation'
import { GridContainer, Header, LogoWrapper, StackStyled } from './header-mini-compact.styled'
import Logo from './logo'

const HeaderCompactMini = ({
	logoProps,
	look,
	multiDrawerMenuProps,
	notifyMessageProps: { message, position, ...notifyMessageProps },
	className,
}) => {
	const { setOpen: setOpenSearch } = useSearchContext()

	return (
		<Header className={cx('HeaderCompactMini-root', className)} message={message} position={position}>
			{message && position === 'top' && <NotifyMessage message={message} {...notifyMessageProps} />}

			<GridContainer alignItems="center" container>
				<MuiGrid item xs={6}>
					<LogoWrapper look={look}>
						<Logo {...logoProps} />
					</LogoWrapper>
				</MuiGrid>

				<MuiGrid alignItems="center" item xs={6}>
					<StackStyled alignItems="center" direction="row" justifyContent="flex-end" spacing={3}>
						<SearchTrigger
							onClick={() => {
								setOpenSearch(true)
							}}
							label={null}
						/>

						<SidebarNavigation
							anchor="right"
							hamburgerButtonProps={{
								className: 'mini-trigger',
							}}
							multiDrawerMenuProps={multiDrawerMenuProps}
						/>
					</StackStyled>
				</MuiGrid>
			</GridContainer>

			{message && position === 'bottom' && <NotifyMessage message={message} {...notifyMessageProps} />}
		</Header>
	)
}

HeaderCompactMini.defaultProps = {
	notifyMessageProps: {
		position: 'bottom',
	},
	variant: 'compact',
}

HeaderCompactMini.propTypes = {
	/**
	 * Logo props
	 */
	logoProps: shape({
		/**
		 * Original height
		 */
		originalHeight: number,
		/**
		 * Original width
		 */
		originalWidth: number,
		/**
		 * Source
		 */
		src: string.isRequired,
	}),
	/**
	 * Multi-drawer menu props
	 */
	multiDrawerMenuProps: shape(MultiDrawerMenu.propTypes),
	/**
	 * Notify message position
	 */
	notifyMessagePosition: oneOf(['top', 'bottom']),
	/**
	 * Notify message props
	 */
	notifyMessageProps: shape({ ...NotifyMessage.propTypes, position: string }),
	/**
	 * Search trigger props
	 */
	searchTriggerProps: shape(SearchTrigger.propTypes),
	/**
	 * Header variant
	 */
	variant: string,
}

export default HeaderCompactMini
